import styled from 'react-emotion';
import * as React from 'react';
import {Trans, t} from '@lingui/macro';
import i18n from './i18n';

type Props = {
  score: number;
};

const Container = styled('span')({
  padding: 2,
  marginBottom: 15,
  textAlign: 'center',
});

const Badge = styled('span')((props: any) => ({
  fontStyle: 'normal',
  borderRadius: 2,
  padding: '2px 5px',
  backgroundColor: props.color,
  textTransform: 'uppercase',
}));

/*

SELECT
  PERCENTILE_DISC(0.50) WITHIN GROUP (ORDER BY score) AS bronze,
  PERCENTILE_DISC(0.75) WITHIN GROUP (ORDER BY score) AS silver,
  PERCENTILE_DISC(0.90) WITHIN GROUP (ORDER BY score) AS gold,
  PERCENTILE_DISC(0.95) WITHIN GROUP (ORDER BY score) AS platinum,
  PERCENTILE_DISC(0.99) WITHIN GROUP (ORDER BY score) AS diamond,
  PERCENTILE_DISC(1.00) WITHIN GROUP (ORDER BY score) AS master
FROM scores

*/

const ranks = [
  {
    min: 0,
    percent: 100,
    color: '#4aa367',
    name: t`beginner`,
  },
  {
    min: 6380,
    percent: 50,
    color: '#cd7f32',
    name: t`bronze`,
  },
  {
    min: 6880,
    percent: 25,
    color: 'silver',
    name: t`silver`,
  },
  {
    min: 7320,
    percent: 10,
    color: 'gold',
    name: t`gold`,
  },
  {
    min: 7530,
    percent: 5,
    color: '#38383A',
    name: t`platinum`,
  },
  {
    min: 7930,
    percent: 1,
    color: '#1B3BA3',
    name: t`diamond`,
  },
  {
    min: 8130,
    percent: 0,
    color: 'black',
    name: t`master`,
  },
];

export function Rank(props: Props) {
  const available = ranks.filter((rank) => rank.min <= props.score);

  if (available.length === 0) {
    return null;
  }

  const rank = available[available.length - 1];
  const hint =
    rank.percent === 100 ? null : rank.percent === 0 ? (
      <Trans>(perfect!)</Trans>
    ) : (
      <Trans>(top {rank.percent}%)</Trans>
    );

  return (
    <Container>
      <Trans>
        Rank: <Badge color={rank.color}>{i18n._(rank.name)}</Badge> {hint}
      </Trans>
    </Container>
  );
}

export function Nudge(props: Props) {
  React.useEffect(() => {
    const w: any = window;
    w.gaData = w.gaData || {};
    w.gaData.expId = '3m-qfwBfRN-u5KmJ1cuwyg';
    w.gaData.expVar =
      localStorage._exv ||
      (localStorage._exv = Math.round(Math.random()).toString());
  }, []);

  const available = ranks.filter((rank) => rank.min <= props.score);

  const nextRank = ranks[available.length];
  if (window.localStorage._exv === '1' && nextRank) {
    const gap = nextRank.min - props.score;
    return (
      <div>
        You are {gap} points away from{' '}
        <Badge color={nextRank.color}>{i18n._(nextRank.name)}</Badge>
      </div>
    );
  }

  return null;
}
