import styled, {keyframes} from 'react-emotion';
import check from './img/check.png';
import cross from './img/cross.png';
import * as React from 'react';
import {Breakpoints} from './SharedStyles';

type Props = {
  correct: boolean;
  description: string;
};

const imageAnimation = keyframes({
  '0%': {
    opacity: 0,
    transform: 'scale(1)',
  },
  '50%': {
    opacity: 1,
    transform: 'scale(1.7)',
  },
  '100%': {
    opacity: 1,
    transform: 'scale(1)',
  },
});

const textAnimation = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 0.8,
  },
});

const imageSize = 64;

const Container = styled('div')({
  position: 'absolute',
  bottom: -imageSize / 4 + 10,
  left: '50%',
  zIndex: 5,
  marginLeft: 150 - imageSize / 2,
  [Breakpoints.width.LT701]: {
    bottom: 'auto',
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    margin: '0 auto',
    position: 'absolute',
    right: 0,
    top: 345, // TODO make this number less magic
  },
});

const Layout = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
});

const Image = styled('img')({
  opacity: 0,
  animation: `${imageAnimation} 0.3s ease forwards`,
  animationDelay: '0.4s',
});

const Description = styled('span')({
  width: 120,
  opacity: 0,
  animation: `${textAnimation} 0.1s ease forwards`,
  animationDelay: '0.4s',
  [Breakpoints.width.LT701]: {
    paddingRight: 16, // the image/icon has a padding
    width: 'auto',
  },
});

export default function Stamp(props: Props) {
  return (
    <Container>
      <Layout>
        <Image
          {...props}
          src={props.correct ? check : cross}
          width={imageSize}
          height={imageSize}
        />
        <Description>{props.description}</Description>
      </Layout>
    </Container>
  );
}
