import styled from 'react-emotion';
import * as React from 'react';
import Logo from './Logo';

export type SurveyData = {
  role: string | null;
  exp: string | null;
};

type Props = {
  onSubmit: (data: SurveyData) => void;
};

const Container = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '30px 10px',
  backgroundColor: '#62448c',
  color: 'white',
  fontSize: 16,
  '& *': {
    maxWidth: 320,
  },
});

const OptionGroupContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const Option = styled('button')((props: {selected: boolean}) => ({
  textAlign: 'left',
  padding: '2px 8px',
  margin: 2,
  borderRadius: 2,
  outline: 'none',
  border: 'none',
  color: props.selected ? 'black' : 'white',
  backgroundColor: props.selected ? 'white' : 'inherit',
}));

const Button = styled('button')({
  alignSelf: 'center',
  fontSize: 26,
  padding: 10,
  paddingLeft: 20,
  paddingRight: 20,
  minWidth: 150,
  margin: 5,
  marginTop: 20,
  borderRadius: 6,
  outline: 'none',
  border: 'none',
  color: 'black',
  backgroundColor: 'white',
  textTransform: 'uppercase',
  '&:active': {
    paddingTop: 11,
    paddingBottom: 9,
  },
});

function OptionGroup(props) {
  return (
    <OptionGroupContainer>
      <p>{props.title}</p>
      {Object.keys(props.options).map((key) => (
        <Option
          key={key}
          selected={key === props.selected}
          onClick={() => props.onSelect(key)}>
          □ {props.options[key]}
        </Option>
      ))}
    </OptionGroupContainer>
  );
}

const ROLES = {
  frontend_eng: 'Frontend Engineer',
  ios_eng: 'iOS Engineer',
  android_eng: 'Android Engineer',
  backend_eng: 'Backend Engineer',
  designer: 'UI/UX Designer',
  pm: 'Project Manager',
  product_manager: 'Product Manager',
  pplm: 'People Manager',
  qa: 'QA',
  marketing: 'Marketing',
  ux: 'UX Research',
  other: 'Other',
  na: 'Not involved',
};

const EXP = {
  '0': '0-1 years',
  '1': '1+ years',
  '2': '2+ years',
  '5': '5+ years',
  '10': '10+ years',
};

export default function Survey(props: Props) {
  const [role, setRole] = React.useState(null);
  const [exp, setExp] = React.useState(null);

  React.useEffect(() => {
    ga('send', 'event', {
      eventCategory: 'Game',
      eventAction: 'Survey Shown',
    });
  }, []);

  let expOptions: any = null;
  if (role && role !== 'na') {
    expOptions = (
      <OptionGroup
        title="How many years of experience do you have?"
        options={EXP}
        selected={exp}
        onSelect={setExp}
      />
    );
  }
  return (
    <Container>
      <Logo>One last thing…</Logo>
      <p style={{marginTop: 40}}>
        I thought it would be fun to see if people's background affects the
        score.
      </p>
      <OptionGroup
        title="Are you involved in building software? If so, what's your role?"
        options={ROLES}
        selected={role}
        onSelect={setRole}
      />
      {expOptions}
      <Button
        onClick={() => {
          ga('send', 'event', {
            eventCategory: 'Game',
            eventAction: 'Survey Answered',
            eventLabel: role,
          });
          window.scroll(0, 0);
          props.onSubmit({role, exp});
        }}>
        Show My Score
      </Button>
    </Container>
  );
}
