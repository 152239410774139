import uuidv4 from 'uuid/v4';

export default function makeClientID(): string {
  const clientID = window.localStorage.getItem('cantunsee_client_id');
  if (clientID) {
    return clientID;
  }

  const newClientID = uuidv4();
  window.localStorage.setItem('cantunsee_client_id', newClientID);
  return newClientID;
}
