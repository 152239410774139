import styled from 'react-emotion';
import * as React from 'react';
import {Trans} from '@lingui/macro';

const Container = styled('div')({
  marginTop: 100,
  maxWidth: 600,
  width: '100%',
  textAlign: 'center',
  '& a': {
    color: 'white',
  },
});

export default function Credits() {
  return (
    <Container>
      <h3>
        <Trans>Credits</Trans>
      </h3>
      <Trans>
        Idea and implementation by{' '}
        <a target="_blank" rel="noreferrer" href="https://frantic.im">
          Alex Kotliarskyi
        </a>
        .
      </Trans>
      <div>
        <Trans>
          Designs by{' '}
          <a target="_blank" rel="noreferrer" href="https://www.amandahum.com/">
            Amanda Hum
          </a>
          .
        </Trans>
      </div>
      <div>
        <Trans>
          Special thanks to{' '}
          <a target="_blank" rel="noreferrer" href="https://keyanzhang.com/">
            Keyan Zhang
          </a>
          .
        </Trans>
      </div>
    </Container>
  );
}
