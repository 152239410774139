import {css} from 'emotion';
import * as React from 'react';
import ALL_LEVELS from './levels';
import {t} from '@lingui/macro';
import {I18n} from '@lingui/react';

const styles = {
  container: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    maxWidth: 400,
    height: 30,
    borderRadius: 15,
    position: 'relative',
    backgroundColor: '#1E1E1E',
    overflow: 'hidden',
  }),
  progress: css({
    display: 'block',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    transition: 'all 0.5s ease',
  }),
  text: css({
    fontSize: 16,
    color: 'white',
    zIndex: 1,
  }),
};

export const configs = [
  {label: t`Tutorial`, color: '#0098FF', complexity: 1},
  {label: t`Easy`, color: '#0098FF', complexity: 2},
  {label: t`Medium`, color: '#851CFF', complexity: 3},
  {label: t`Hard`, color: '#FF1F5D', complexity: 4},
];

type Props = {
  level: number;
  levels: typeof ALL_LEVELS;
  hasSelected: boolean;
};

export default function ProgressBar(props: Props) {
  const current = props.levels[props.level];
  const complexity = current.complexity;
  const offset = props.levels.filter((e) => e.complexity < complexity).length;
  const max = props.levels.filter((e) => e.complexity === complexity).length;
  const index = props.level - offset;
  const config = configs[complexity - 1];
  const selected = props.hasSelected ? 1 : 0;

  return (
    <div className={styles.container}>
      <div
        key={complexity}
        className={styles.progress}
        style={{
          width: Math.round((100 * (index + selected) + 3) / max) + '%',
          backgroundColor: config.color,
        }}
      />
      <I18n>
        {({i18n}) => (
          <span className={styles.text}>
            {i18n._(config.label)} {index + 1} / {max}
          </span>
        )}
      </I18n>
    </div>
  );
}
