import styled from 'react-emotion';
import React, {useState, useCallback} from 'react';
import Logo from './Logo';
import {Trans} from '@lingui/macro';

const Container = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '100px 10px 30px 10px',
  backgroundColor: '#1E1E1E',
  color: 'white',
  fontSize: 16,
});

const Button = styled('button')({
  alignSelf: 'center',
  fontSize: 26,
  padding: 10,
  paddingLeft: 20,
  paddingRight: 20,
  minWidth: 150,
  margin: 5,
  marginTop: 20,
  borderRadius: 6,
  outline: 'none',
  border: 'none',
  color: 'black',
  backgroundColor: 'white',
  textTransform: 'uppercase',
  '&:disabled': {
    opacity: 0.7,
  },
});

const Form = styled('form')({
  display: 'flex',
  alignSelf: 'center',
  flexDirection: 'column',
  maxWidth: 600,
  '& > h1': {
    lineHeight: 1,
    fontWeight: 500,
  },
});

const Input = styled('input')({
  display: 'flex',
  fontSize: 16,
  outline: 'none',
  backgroundColor: '#4f4f4f',
  margin: '5px 0',
  padding: 4,
  width: '100%',
  border: '1px solid white',
  borderRadius: 3,
  '::placeholder': {
    color: '#FFFFFF88',
  },
});

export default function Business(props: any) {
  const name = useInputState();
  const email = useInputState();
  const job = useInputState();

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  async function onSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    setSubmitting(true);
    try {
      const response = await fetch('/api/business', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name.value,
          email: email.value,
          job: job.value,
        }),
      });
      if (response.status !== 201) {
        throw new Error('Something went wrong');
      }
      setSubmitted(true);
    } catch (e) {
      alert(e);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <h1>Use Can't Unsee to&nbsp;measure attention&nbsp;to&nbsp;detail</h1>
        <p>
          Our data suggests that high scores in the Can't&nbsp;Unsee game are
          correlated with high attention to detail.
        </p>
        <p>
          You can use Can't Unsee to screen designers, developers, QAs, product
          managers and other people who job with technology.
        </p>
        <p>
          This is how it works: after joining our program you'll get a custom
          link. Share this link with your candidates, and you'll see their
          results in a dashboard (custom integrations available).
        </p>
        {submitted ? (
          <>
            <Logo>
              <Trans>Thanks!</Trans>
            </Logo>
            <p>We'll be in contact shortly</p>
          </>
        ) : (
          <>
            <Input
              {...name}
              disabled={submitting}
              type="text"
              placeholder="Your name"
            />
            <Input
              {...email}
              disabled={submitting}
              type="email"
              placeholder="job e-mail address"
            />
            <Input
              {...job}
              disabled={submitting}
              type="text"
              placeholder="What do you do, e.g. hiring manager, art director, etc."
            />
            <Button
              type="submit"
              onClick={onSubmit}
              disabled={
                submitting || !name.value || !email.value || !job.value
              }>
              {submitting ? 'Sending ...' : 'Request a Demo'}
            </Button>
          </>
        )}
      </Form>
    </Container>
  );
}

function useInputState() {
  const [value, setValue] = useState('');
  const onChange = useCallback((event) => setValue(event.target.value), []);
  return {value, onChange};
}
