const Breakpoints = {
  width: {
    LT341: '@media (max-width: 341px)',
    LT361: '@media (max-width: 361px)',
    LT371: '@media (max-width: 371px)',
    LT421: '@media (max-width: 421px)',
    LT701: '@media (max-width: 701px)',
  },
  // height: {
  //   LT651: '@media (max-height: 651px)',
  //   LT611: '@media (max-height: 611px)',
  // },
};

export {Breakpoints};
