import {css, injectGlobal} from 'emotion';
import React, {Suspense, lazy} from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import font from './FSEX302.ttf';
import {unregister} from './registerServiceWorker';
import {Router} from '@reach/router';
import Leaderboard from './Leaderboard';
import Intro from './Intro';
import TotalScore from './TotalScore';
import {I18nProvider} from '@lingui/react';
import i18n from './i18n';

import 'normalize.css';
import Business from './Business';
import AppForBusiness from './AppForBusiness';

const Console = lazy(() => import('./Console'));

injectGlobal(`
  @font-face {
    font-family: 'Fixedsys';
    src: url('${font}') format('truetype');
  }  
  * {
    box-sizing: border-box;
  }
  body, input, button {
    margin: 0;
    padding: 0;
    font-family: Fixedsys;
    color: white;
    background-color: #2e2e2e;
    font-smooth: never;
  }
  html, body {
    height: 100%;
  }
  #root {
    display: flex;
    min-height: 100%;
    width: 100%;
  }
`);

const rootStyle = css({
  width: '100%',
});

console.log(`Hi! 👋

I hope you enjoy playing this game! Of course you can find a
way to get a perfect score by accessing the devtools. But
this kinda misses the point of the game: raising awareness
of tiny interface details that make products feel polished.

However, if that's your thing and you are having fun hacking
the game -- go ahead!
`);

function render() {
  ReactDOM.render(
    <I18nProvider i18n={i18n}>
      <Suspense fallback={<div>Loading...</div>}>
        <Router className={rootStyle}>
          <App path="/" />
          <AppForBusiness path="/b/:boardID" />
          <Intro path="/intro" />
          <Leaderboard path="/leaderboard" />
          <Business path="/business" />
          <Console path="/console" />
          <TotalScore path="/score/:shortcut" />
        </Router>
      </Suspense>
    </I18nProvider>,
    document.getElementById('root') as HTMLElement
  );
}

render();
unregister();
