import styled, {css} from 'react-emotion';
import * as React from 'react';
import HighScores from './HighScores';
import Logo from './Logo';
import Credits from './Credits';
import {Link} from '@reach/router';
import {Trans} from '@lingui/macro';

const Container = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '100px 10px 30px 10px',
  backgroundColor: '#62448c',
  color: 'white',
  fontSize: 16,
});

const button = css({
  alignSelf: 'center',
  fontSize: 26,
  padding: 10,
  width: 150,
  margin: 20,
  borderRadius: 6,
  outline: 'none',
  border: 'none',
  color: 'black',
  backgroundColor: 'white',
  cursor: 'default',
  textTransform: 'uppercase',
  textDecoration: 'none',
  textAlign: 'center',
  '&:active': {
    paddingTop: 11,
    paddingBottom: 9,
  },
});

export default function Leaderboard(props: any) {
  return (
    <Container>
      <Logo>
        <Trans>Leaderboard</Trans>
      </Logo>
      <HighScores uid={null} />
      <Link className={button} to="/">
        <Trans>Play</Trans>
      </Link>
      <Credits />
    </Container>
  );
}
