import {setupI18n} from '@lingui/core';

/*

  These files are generated and not checked in. If
  you are seeing "ENOENT ... messages.js", run:

  $ yarn compile

*/

import en from './locales/en/messages.js';
import uk from './locales/uk/messages.js';
import de from './locales/de/messages.js';
import zh from './locales/zh/messages.js';

const catalogs = {
  en,
  uk,
  de,
  zh,
};
let language = navigator.language.substring(0, 2);
const param = window.location.href.match(/lang=(\w+)/);
if (param && param[1]) {
  language = param[1];
}

// For some reason, when language doesn't exist in catalogs,
// lingui has a bug where it inlines variable names: "Completed in {timeString}"
// but only in prod
if (!catalogs[language]) {
  language = 'en';
}

export default setupI18n({
  language,
  catalogs,
});
