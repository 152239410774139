import {css, cx} from 'emotion';
import * as React from 'react';
import {Breakpoints} from './SharedStyles';

const WIDTH = 300;
const HEIGHT = 200;

function getMarginBasedLayout(margin: number) {
  return {
    card: {margin},
    left: {
      desktop: {
        transform: `translateX(${(WIDTH + 2 * margin) / 2}px)`,
      },
      mobile: {
        transform: `translateY(${(HEIGHT + 2 * margin) / 2}px)`,
      },
    },
    right: {
      desktop: {
        transform: `translateX(-${(WIDTH + 2 * margin) / 2}px)`,
      },
      mobile: {
        transform: `translateY(-${(HEIGHT + 2 * margin) / 2}px)`,
      },
    },
  };
}

const styles = {
  card: css({
    display: 'flex',
    flexShrink: 0,
    width: WIDTH,
    height: HEIGHT,
    backgroundColor: '#113',
    borderRadius: 8,
    transition: 'transform 0.2s ease',
    overflow: 'hidden',
    cursor: 'pointer',
    touchAction: 'manipulation',
    ...getMarginBasedLayout(20).card,
    [Breakpoints.width.LT701]: {
      ...getMarginBasedLayout(10).card,
    },
    [Breakpoints.width.LT341]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
  left: css({
    ...getMarginBasedLayout(20).left.desktop,
    [Breakpoints.width.LT701]: {
      ...getMarginBasedLayout(10).left.mobile,
    },
  }),
  right: css({
    ...getMarginBasedLayout(20).right.desktop,
    [Breakpoints.width.LT701]: {
      ...getMarginBasedLayout(10).right.mobile,
    },
  }),
  behind: css({
    zIndex: -1,
  }),
  hidden: css({
    visibility: 'hidden',
  }),
  image: css({
    height: '100%',
    width: '100%',
  }),
};

type Props = {
  imageUrl: string;
  position: 'left' | 'right';
  collapsed: boolean;
  selected: boolean;
  hidden: boolean;
  onClick?: () => any;
};

export default function Card(props: Props) {
  return (
    <div
      onClick={props.onClick}
      className={cx({
        [styles.card]: true,
        [styles.left]: props.collapsed && props.position === 'left',
        [styles.right]: props.collapsed && props.position === 'right',
        [styles.behind]: props.collapsed && !props.selected,
        [styles.hidden]: props.hidden,
      })}>
      <img className={styles.image} src={props.imageUrl} alt="card" />
    </div>
  );
}
