import styled from 'react-emotion';
import * as React from 'react';
import {Breakpoints} from './SharedStyles';
import {Trans} from '@lingui/macro';

type ElementProps = {
  color: string;
  offset: number;
};

const LogoElement = styled('div')((props: ElementProps) => ({
  color: props.color,
  position: 'absolute',
  top: props.offset,
  left: 4 - props.offset,
  fontSize: 34,
  fontWeight: 'bold',
  whiteSpace: 'pre',
}));

const Hidden = styled('div')({
  visibility: 'hidden',
  fontSize: 34,
  fontWeight: 'bold',
  whiteSpace: 'pre',
});

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  height: 32,
  top: -4,
  [Breakpoints.width.LT421]: {
    top: 0,
    '& div': {
      fontSize: 28,
    },
  },
  [Breakpoints.width.LT371]: {
    top: 2,
    '& div': {
      fontSize: 24,
    },
  },
});

type Props = {
  children: React.ReactNode;
};

export default function Header(props: Props) {
  return (
    <Container>
      <Hidden>{props.children}</Hidden>
      <LogoElement color="#FF1F5D" offset={-2}>
        {props.children}
      </LogoElement>
      <LogoElement color="#851CFF" offset={0}>
        {props.children}
      </LogoElement>
      <LogoElement color="#0098FF" offset={2}>
        {props.children}
      </LogoElement>
    </Container>
  );
}

Header.defaultProps = {
  children: <Trans>Can't Unsee</Trans>,
};
