import {css} from 'react-emotion';
import * as React from 'react';
import formatMillis from './formatMillis';
import {Trans} from '@lingui/macro';

type Props = {
  uid: string | null;
};

const container = css({
  marginTop: 60,
  marginBottom: 60,
  color: 'white',
  textAlign: 'left',
});

const currentUserRow = css({
  backgroundColor: '#FF205D',
});

export default function HighScores({uid}: Props) {
  const scores = useHighScores();
  if (scores === null) {
    return (
      <div className={container}>
        <Trans>Loading...</Trans>
      </div>
    );
  }
  if (scores instanceof Error) {
    return (
      <div className={container}>
        <Trans>Can't load high scores right now.</Trans>
      </div>
    );
  }

  return (
    <table cellSpacing="0" cellPadding="4" className={container}>
      <thead>
        <tr>
          <th>
            <Trans>Rank</Trans>
          </th>
          <th>
            <Trans>Name</Trans>
          </th>
          <th>
            <Trans>Score</Trans>
          </th>
          <th>
            <Trans>Time</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {scores.map((row, index) => (
          <ScoreRow score={row} key={row.id} uid={uid} rank={index + 1} />
        ))}
      </tbody>
    </table>
  );
}

function useHighScores() {
  const initialState: any = null;
  const [scores] = React.useState(initialState);
  return scores;
}

function ScoreRow({score, uid, rank}) {
  const data = score.data();
  const isCurrentUser = uid && data.uid === uid;
  return (
    <tr className={isCurrentUser ? currentUserRow : undefined}>
      <td className={css({textAlign: 'center'})}>{rank} </td>
      <td>{data.name}</td>
      <td>{data.score}</td>
      <td>{formatMillis(data.millis)}</td>
    </tr>
  );
}
