import React, {useEffect, useRef} from 'react';
import {css, cx} from 'emotion';

const container = css`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const carbon = css`
  #carbonads {
    // max-width: 500px;
  }
  #carbonads > span {
    display: flex;
    flex-direction: column;
  }
  #carbonads .carbon-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  #carbonads .carbon-wrap span {
    display: flex;
  }
  #carbonads .carbon-wrap .carbon-img {
    display: flex;
  }
  #carbonads .carbon-wrap .carbon-text {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 8px;
  }
  #carbonads .carbon-poweredby {
    text-align: right;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }
  #carbonads a {
    color: inherit;
    text-decoration: none;
  }
  #carbonads a:hover {
    color: inherit;
  }
`;

type Props = {
  onFailedToLoad: () => void;
};

export default function CarbonAd(props: Props) {
  const ref = useRef(null);
  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      '//cdn.carbonads.com/carbon.js?serve=CK7IT53N&placement=cantunseespace';
    script.async = true;
    script.id = '_carbonads_js';
    script.onerror = props.onFailedToLoad;

    const container: any = ref.current;
    container.appendChild(script);

    return () => {
      container.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={cx(container, carbon)} ref={ref} />;
}
