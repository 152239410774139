import React, {useState, useEffect, useCallback} from 'react';
import {Board} from './Console';
import styled from 'react-emotion';
import App from './App';

const Fullscreen = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const Form = styled('form')({
  width: '100%',
  maxWidth: 520,
  display: 'flex',
  flexDirection: 'column',
  padding: 20,
  border: '1px solid #ccc',
  borderRadius: 4,
});

const Button = styled('button')({
  alignSelf: 'center',
  fontSize: 18,
  padding: 6,
  paddingLeft: 12,
  paddingRight: 12,
  marginTop: 10,
  borderRadius: 2,
  outline: 'none',
  border: 'none',
  backgroundColor: '#62448c',
  transition: 'all 0.1s ease',
  position: 'relative',
  '&:active': {
    top: 2,
  },
});

const Input = styled('input')({
  fontSize: 18,
  padding: 6,
  marginTop: 10,
  borderRadius: 2,
  outline: 'none',
  border: 'none',
  borderBottom: 'solid 1px #eee',
  transition: 'all 0.1s ease',
  position: 'relative',
});

type Props = {
  path: string;
  boardID?: string;
};

type Attempt = {
  name: string;
  startedAt: number;
};

export default function AppForBusiness(props: Props) {
  const [board, setBoard] = useState<Board | null>(null);
  const [attempt, setAttempt] = useState<Attempt | null>(null);
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    fetch(`/api/board?id=${props.boardID}`)
      .then((response) => response.json())
      .then(({board, error}) => {
        setBoard(board);
      });
  }, [props.boardID]);

  function submitForm(e) {
    e.preventDefault();
    if (!board) {
      return;
    }

    setAttempt({
      name: e.target.name.value,
      startedAt: new Date().getTime(),
    });
  }

  const submitScore = useCallback(
    (details) => {
      if (attempt) {
        fetch(`/api/board?id=${props.boardID}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...details,
            ...attempt,
            completedAt: new Date().getTime(),
          }),
        });
      }
      setGameOver(true);
    },
    [attempt, props.boardID]
  );

  if (!board) {
    return <Fullscreen>Loading...</Fullscreen>;
  }

  if (!attempt) {
    return (
      <Fullscreen>
        <Form onSubmit={submitForm}>
          <p>
            {board.name} is offering you to participate in a short quiz that's
            focused on UI/UX details. It will take 10-15 minutes.
          </p>
          <p>Your results will be shared with {board.name}.</p>
          <Input
            id="name"
            type="text"
            placeholder="Enter your name"
            autoFocus
            required
          />
          <Button type="submit">Accept &amp; Continue</Button>
        </Form>
      </Fullscreen>
    );
  }

  if (gameOver) {
    return (
      <Fullscreen>
        <p>
          Thank you! Your score has been recorded and the result has been shared
          with {board.name}
        </p>
      </Fullscreen>
    );
  }

  return <App onSubmitScore={submitScore} onlyLevels={board.levels} />;
}
