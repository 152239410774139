import styled, {keyframes} from 'react-emotion';
import * as React from 'react';
import makeClientID from './makeClientID';
import coin from './img/coin.png';
import Logo from './Logo';
import {Rank, Nudge} from './Rank';
import formatMillis from './formatMillis';
import Credits from './Credits';
import {Trans} from '@lingui/macro';
import {LevelStats} from './App';

import {SurveyData} from './Survey';

type Props = {
  score: number;
  stats: {[level: string]: LevelStats};
  millis: number;
  surveyData: SurveyData | null;
  onRestart(): void;
};

const appear = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

const Container = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '100px 10px 100px 10px',
  backgroundColor: '#62448c',
  color: 'white',
  fontSize: 16,
});

const Score = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 20,
  fontSize: 60,
  color: 'white',
  fontWeight: 'bold',
  flexDirection: 'row',
  '& img': {
    marginRight: 10,
    // animation: `${bounce} 2s ease-in-out infinite`,
  },
});

const SharingButtons = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  padding: 20,
  '& *': {
    marginLeft: 2,
    marginRight: 2,
  },
  '& a': {
    color: 'white',
  },
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 80,
});

const Button = styled('button')({
  alignSelf: 'center',
  fontSize: 26,
  padding: 10,
  paddingLeft: 20,
  paddingRight: 20,
  minWidth: 150,
  margin: 5,
  marginTop: 20,
  borderRadius: 6,
  outline: 'none',
  border: 'none',
  color: 'black',
  backgroundColor: 'white',
  textTransform: 'uppercase',
  '&:active': {
    paddingTop: 11,
    paddingBottom: 9,
  },
});

const SignupBox = styled('form')({
  display: 'flex',
  alignSelf: 'center',
  flexDirection: 'column',
  maxWidth: 320,
  marginTop: 80,
  border: '1px solid white',
  borderRadius: 3,
  padding: 10,
  opacity: 0,
  animation: `${appear} 2s ease-in-out 1s forwards`,
  '& h3': {
    marginTop: 0,
  },
});

const Input = styled('input')({
  display: 'flex',
  fontSize: 16,
  outline: 'none',
  backgroundColor: '#62448C',
  padding: 4,
  width: '100%',
  border: '1px solid white',
  borderRadius: 3,
  '::placeholder': {
    color: '#FFFFFF88',
  },
});

export default function GameOver(props: Props) {
  React.useEffect(() => {
    mixpanel.track('Game Over', {score: props.score, millis: props.millis});
    ga('send', 'event', {
      eventCategory: 'Game',
      eventAction: 'Game Over',
      eventValue: props.score,
    });
    fetch('/api/scores', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        client_id: makeClientID(),
        score: props.score,
        millis: props.millis,
        role: props.surveyData ? props.surveyData.role : null,
        exp: props.surveyData ? props.surveyData.exp : null,
      }),
    }).catch();

    const params = new URLSearchParams(window.location.search);
    const email = params.get('email');
    const from = params.get('from');

    if (email) {
      fetch('/api/sheet', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          client_id: makeClientID(),
          from,
          email,
          score: props.score,
          millis: props.millis,
          stats: props.stats,
        }),
      }).catch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const w: any = window;
    if (
      w.twttr &&
      w.twttr.widgets &&
      typeof w.twttr.widgets.load === 'function'
    ) {
      w.twttr.widgets.load();
    }
    if (w.FB && w.FB.XFBML && typeof w.FB.XFBML.parse === 'function') {
      w.FB.XFBML.parse();
    }
  }, []);

  const restart = React.useCallback(() => {
    ga('send', 'event', {
      eventCategory: 'Game',
      eventAction: 'Restart',
    });
    props.onRestart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onRestart]);

  const timeString = formatMillis(props.millis);

  let encodedData = props.score + '.' + props.millis / 1000;
  while (encodedData.length % 3 !== 0) {
    encodedData += '.'; // To avoid Base64 padding
  }
  // const shareShortcut = btoa(encodedData);
  // const shareURL = 'https://share.cantunsee.space/' + shareShortcut;
  const shareURL = 'https://cantunsee.space/';

  return (
    <Container>
      <Logo />
      <Score>
        <img key={props.score} src={coin} width={60} height={60} alt="coin" />
        {props.score}
      </Score>
      <Rank score={props.score} />
      <span>
        <Trans>Completed in {timeString}</Trans>
      </span>
      <SharingButtons>
        <a
          href={`https://twitter.com/share?url=${shareURL}`}
          className="twitter-share-button"
          data-size="large"
          data-text={`I got ${props.score} points in a fun game where you need to find design mistakes`}
          data-url={shareURL}
          data-related="alex_frantic,keyanzhang"
          data-lang="en"
          data-dnt="true"
          data-show-count="false">
          Tweet
        </a>
        <div
          className="fb-share-button"
          data-href={shareURL}
          data-layout="button"
          data-size="large"
          data-mobile-iframe="true">
          <a
            rel="noreferrer"
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              shareURL
            )}&amp;src=sdkpreparse`}
            className="fb-xfbml-parse-ignore">
            Share
          </a>
        </div>
      </SharingButtons>
      <SignupBox
        action="https://frantic.us15.list-manage.com/subscribe/post?u=bab4478ea2c80e4f7cbf17e59&amp;id=bb0437bcca"
        method="post"
        target="_blank">
        <h3>How did other people do?</h3>
        <p>
          I'm going to analyze the data and share fun insights. Sign up to
          receive them to your inbox. No spam.
        </p>
        <Input type="email" defaultValue="" name="EMAIL" placeholder="E-mail" />
        <input type="hidden" value={props.score} name="SCORE" />
        <Button type="submit">Signup</Button>
      </SignupBox>
      <ButtonContainer>
        <Nudge score={props.score} />
        <Button onClick={restart}>
          <Trans>Play Again</Trans>
        </Button>
      </ButtonContainer>
      <Credits />
    </Container>
  );
}
