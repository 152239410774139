const THRESHOLD = 128;

export default function detectDevtools(callback) {
  function areDevToolsOpen() {
    return (
      window.outerWidth - window.innerWidth > THRESHOLD ||
      window.outerHeight - window.innerHeight > THRESHOLD
    );
  }

  return function effect() {
    if (areDevToolsOpen()) {
      callback();
      return () => {};
    }

    function onResize() {
      if (areDevToolsOpen()) {
        callback();
        unsubscribe();
      }
    }

    function unsubscribe() {
      window.removeEventListener('resize', onResize);
    }

    window.addEventListener('resize', onResize);
    return unsubscribe;
  };
}
