import styled from 'react-emotion';
import * as React from 'react';

const Container = styled('div')({
  marginTop: 100,
  maxWidth: 600,
  width: '100%',
  '& a': {
    color: 'white',
  },
});

export default function Intro(props: any) {
  return (
    <Container>
      <Typewriter>
        “It has long been an axiom of mine that the little things are infinitely
        the&nbsp;most&nbsp;important” -- Sir Arthur Conan Doyle
      </Typewriter>
    </Container>
  );
}

function Typewriter(props: {children: any}) {
  const text = props.children;
  const [len, setLen] = React.useState(0);

  React.useEffect(
    () => {
      let index = 0;
      const handle = setInterval(tick, 30);
      function tick() {
        if (index > text.length) {
          clearInterval(handle);
        } else {
          setLen(index++);
        }
      }
      return () => {
        clearInterval(handle);
      };
    },
    [text]
  );

  return text.substring(0, len);
}
