import styled, {keyframes} from 'react-emotion';
import * as React from 'react';
import coin from './img/coin.png';

type Props = {
  score: number;
};

const coinAnimation = keyframes({
  '0%': {
    transform: 'translateY(-40px)',
  },
  '50%': {
    transform: 'translateY(0px)',
  },
  '75%': {
    transform: 'scale(1.7)',
  },
  '100%': {
    transform: 'scale(1)',
  },
});

const Container = styled('div')((props: Props) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 10,
  paddingBottom: 10,
  paddingLeft: 20,
  paddingRight: 20,
  borderRadius: 6,
  backgroundColor: '#935CE0',
  color: 'white',
  fontSize: 16,
  '& img': {
    marginRight: 10,
    animation: props.score > 0 ? `${coinAnimation} 0.2s` : '',
  },
}));

export default function Score(props: Props) {
  return (
    <Container {...props}>
      <img key={props.score} src={coin} width={20} height={20} alt="coin" />
      {formatScore(props.score)}
    </Container>
  );
}

function formatScore(score: number): string {
  let str = score.toString();
  return '0'.repeat(Math.max(0, 6 - str.length)) + str;
}
