export default function createGameTimer() {
  let seconds = 0;
  let interval: number | undefined = undefined;

  window.addEventListener('focus', startTimer);
  window.addEventListener('blur', stopTimer);

  function timerHandler() {
    seconds++;
  }

  function startTimer() {
    if (interval === undefined) {
      interval = window.setInterval(timerHandler, 1000);
    }
  }

  function stopTimer() {
    window.clearInterval(interval);
    interval = undefined;
  }

  startTimer();
  return {
    getMillis() {
      return seconds * 1000;
    },
  };
}
