import styled from 'react-emotion';
import * as React from 'react';
import Card from './Card';
import ProgressBar from './ProgressBar';
import Logo from './Logo';
import Score from './Score';
import GameOver from './GameOver';
import Stamp from './Stamp';
import ALL_LEVELS from './levels';
import createGameTimer from './createGameTimer';
import detectDevtools from './detectDevtools';
import PageCloseGuard from './PageCloseGuard';
import CarbonAd from './CarbonAd';
import CarbonNativeAd from './CarbonNativeAd';
import Survey from './Survey';
import {Breakpoints} from './SharedStyles';
import {Trans} from '@lingui/macro';
import {I18n} from '@lingui/react';

const Container = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const SupportUkraine = styled('a')({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: 6,
  fontSize: 14,
  textAlign: 'center',
  color: 'gray',
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: 'white',
    color: '#1E1E1E',
  },
  [Breakpoints.width.LT701]: {
    left: 0,
  },
});

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  maxWidth: 800,
  alignSelf: 'center',
  padding: 40,
  [Breakpoints.width.LT421]: {
    padding: 32,
  },
  [Breakpoints.width.LT361]: {
    padding: 24,
  },
});

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
});

const GameField = styled('div')((props: {collapsed: boolean}) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: props.collapsed ? 'none' : 'auto',
  [Breakpoints.width.LT701]: {
    flexDirection: 'column',
  },
}));

const Instructions = styled('div')(
  (props: {holdingShift: boolean; isHidden: boolean}) => ({
    display: props.isHidden ? 'none' : 'block',
    fontSize: 16,
    marginTop: 20,
    textAlign: 'center',
    '& strong': {
      fontWeight: 'normal',
      display: 'inline-block',
      padding: 2,
      paddingLeft: 6,
      paddingRight: 6,
      borderRadius: 2,
      color: 'white',
      backgroundColor: '#1E1E1E',
    },
    '& strong:not(:nth-child(3))': {
      color: props.holdingShift ? 'black' : 'white',
      backgroundColor: props.holdingShift ? 'white' : '#1E1E1E',
    },
  })
);

const BigButton = styled('button')((props: {disabled?: boolean}) => ({
  alignSelf: 'center',
  fontSize: 34,
  padding: 10,
  paddingLeft: 20,
  paddingRight: 20,
  margin: 20,
  borderRadius: 6,
  outline: 'none',
  border: 'none',
  backgroundColor: '#62448c',
  transition: 'all 0.1s ease',
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  userSelect: 'none', // TODO: Does this work on Android?
  touchAction: 'manipulation',
  position: 'relative',
  '&:disabled': {
    opacity: 0.4,
  },
  '&:active': {
    top: 2,
  },
}));

const ButtonsContainer = styled('div')((props: {show: boolean}) => ({
  display: props.show ? 'flex' : 'none',
  justifyContent: 'center',
  margin: '0 4px',
}));

const AdContainer = styled('div')({
  maxWidth: 500,
  minHeight: 200,
  padding: 40,
  [Breakpoints.width.LT421]: {
    padding: 32,
  },
  [Breakpoints.width.LT361]: {
    padding: 24,
  },
});

const Footer = styled('div')({
  margin: '25px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export type LevelStats = {[id: string]: boolean};

type State = {
  from: string | null;
  level: number;
  levels: typeof ALL_LEVELS;
  correctAnswer: 'left' | 'right';
  selected: null | 'left' | 'right';
  compare: boolean;
  score: number;
  stats: {[level: string]: LevelStats};
  gameOver: boolean;
  hasEverUsedShift: boolean;
  millis: number;
  userID: string;
  session: any;
  adsAvailable: boolean;
};

const initialState: State = {
  from: new URLSearchParams(window.location.search).get('from'),
  level: 0,
  levels: ALL_LEVELS,
  correctAnswer: flipCoin(0),
  selected: null,
  compare: false,
  score: 0,
  stats: {},
  gameOver: false,
  millis: 0,
  hasEverUsedShift: false,
  userID: '',
  session: null,
  adsAvailable: true,
};

type Action =
  | {type: 'SHIFT_DOWN'}
  | {type: 'SHIFT_UP'}
  | {type: 'SELECT'; answer: 'left' | 'right'}
  | {type: 'NEXT'; millis: number}
  | {type: 'AD_FAILED_TO_LOAD'}
  | {type: 'RESTART'};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SHIFT_DOWN':
      return {
        ...state,
        compare: true,
      };

    case 'SHIFT_UP':
      return {
        ...state,
        compare: false,
        hasEverUsedShift: state.hasEverUsedShift || state.compare,
      };

    case 'SELECT':
      if (state.selected) {
        return state;
      }
      const {complexity, id} = state.levels[state.level];
      const correct = action.answer === state.correctAnswer;
      const points = correct ? pointsForComplexity(complexity) : 0;
      mixpanel.track('Select', {id, correct});
      ga('send', 'event', {
        eventCategory: 'Level ' + complexity,
        eventAction: 'Select',
        eventLabel: id,
        eventValue: correct ? 1 : 0,
      });

      const levelStats = {...state.stats[complexity], [id]: correct};
      return {
        ...state,
        selected: action.answer,
        score: state.score + points,
        stats: {
          ...state.stats,
          [complexity]: levelStats,
        },
      };

    case 'NEXT':
      if (!state.selected) {
        return state;
      }
      const {level, gameOver} = state;
      const nextLevel = level + 1;
      if (nextLevel >= state.levels.length && !gameOver) {
        return {
          ...state,
          gameOver: true,
          millis: action.millis,
        };
      }
      return {
        ...state,
        level: nextLevel,
        correctAnswer: flipCoin(nextLevel),
        selected: null,
        compare: false,
      };

    case 'AD_FAILED_TO_LOAD':
      return {...state, adsAvailable: false};

    case 'RESTART':
      return initialState;

    default:
      return state;
  }
}

type Props = {
  path?: string;
  onlyLevels?: string[] | null;
  onSubmitScore?: (details: {
    score: number;
    millis: number;
    stats: {[level: string]: LevelStats};
  }) => void;
};

export default function App(props: Props) {
  const [state, dispatch] = React.useReducer(reducer, {
    ...initialState,
    // Don't show ads if we have a custom onSubmitScore
    adsAvailable: !props.onSubmitScore,
    // Only show selected levels
    levels: props.onlyLevels
      ? ALL_LEVELS.filter((level) => props.onlyLevels?.includes(level.id))
      : ALL_LEVELS,
  });
  // TODO: This leaks memory
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timer = React.useMemo(() => createGameTimer(), [state.gameOver]);

  useEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Shift' && !event.metaKey) {
      dispatch({type: 'SHIFT_DOWN'});
    }
  });
  useEventListener('keyup', (event: KeyboardEvent) => {
    if (event.key === ' ' || event.keyCode === 13) {
      dispatch({type: 'NEXT', millis: timer.getMillis()});
    }
    if (event.key === '1' || event.keyCode === 37) {
      dispatch({type: 'SELECT', answer: 'left'});
    }
    if (event.key === '2' || event.keyCode === 39) {
      dispatch({type: 'SELECT', answer: 'right'});
    }
    if (event.key === 'Shift') {
      dispatch({type: 'SHIFT_UP'});
    }
  });
  React.useEffect(preloadImages, []);
  React.useEffect(() => {
    mixpanel.track('Start Game');
    ga('send', 'event', {
      eventCategory: 'Game',
      eventAction: 'Start',
      nonInteraction: true,
    });
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(
    detectDevtools(() => {
      if (process.env.NODE_ENV === 'production') {
        ga('send', 'event', {
          eventCategory: 'Misc',
          eventAction: 'Devtools Open',
        });
      }
    }),
    []
  );
  React.useEffect(() => {
    if (props.onSubmitScore && state.gameOver) {
      props.onSubmitScore({
        score: state.score,
        millis: state.millis,
        stats: state.stats,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onSubmitScore, state.gameOver]);

  const isMobileLayout = useIsMobileLayout();
  const isTouchScreen = useIsTouchScreen();

  const {
    level,
    levels,
    correctAnswer,
    selected,
    compare,
    score,
    stats,
    gameOver,
    millis,
    hasEverUsedShift,
    adsAvailable,
  } = state;

  const [surveyData, setSurveyData] = useLocalStorageState('survey_data', null);

  if (gameOver) {
    if (!surveyData) {
      return <Survey onSubmit={setSurveyData} />;
    }
    return (
      <GameOver
        score={score}
        stats={stats}
        millis={millis}
        surveyData={surveyData}
        onRestart={() => dispatch({type: 'RESTART'})}
      />
    );
  }
  const images = levels[level];

  let instructions = <Trans>Select the design that is most correct</Trans>;
  if (selected) {
    if (isTouchScreen) {
      instructions = (
        <Trans>
          Press and hold <strong>COMPARE</strong> to compare
        </Trans>
      );
    } else if (hasEverUsedShift) {
      instructions = (
        <Trans>
          Press and hold <strong>COMPARE</strong> or <strong>SHIFT</strong> to
          compare, <strong>ENTER</strong> to continue.
        </Trans>
      );
    } else {
      instructions = (
        <Trans>
          Press and hold <strong>COMPARE</strong> or <strong>SHIFT</strong> to
          compare
        </Trans>
      );
    }
  }

  const stamp = selected ? (
    <I18n>
      {({i18n}) => (
        <Stamp
          description={i18n._(images.description)}
          correct={selected === correctAnswer ? !compare : compare}
        />
      )}
    </I18n>
  ) : null;

  const onShiftDown = () => dispatch({type: 'SHIFT_DOWN'});
  const onShiftUp = () => dispatch({type: 'SHIFT_UP'});
  const onNext = () => dispatch({type: 'NEXT', millis: timer.getMillis()});
  const onAdFailedToLoad = () => dispatch({type: 'AD_FAILED_TO_LOAD'});

  let content = (
    <Content>
      <GameField collapsed={isMobileLayout && selected !== null}>
        <Card
          hidden={compare && selected === 'left'}
          imageUrl={
            correctAnswer === 'left' ? images.correct : images.incorrect
          }
          onClick={() => dispatch({type: 'SELECT', answer: 'left'})}
          collapsed={selected !== null}
          selected={selected === 'left'}
          position="left"
        />
        <Card
          hidden={compare && selected === 'right'}
          imageUrl={
            correctAnswer === 'right' ? images.correct : images.incorrect
          }
          onClick={() => dispatch({type: 'SELECT', answer: 'right'})}
          collapsed={selected !== null}
          selected={selected === 'right'}
          position="right"
        />
        {stamp}
      </GameField>
      <Instructions
        holdingShift={compare}
        isHidden={
          isTouchScreen && selected !== null && hasEverUsedShift && level !== 0
        }>
        {instructions}
      </Instructions>
      <ButtonsContainer show={selected !== null}>
        <BigButton
          title="Or press and hold <SHIFT> to compare"
          onContextMenu={() => false}
          onMouseDown={onShiftDown}
          onMouseLeave={onShiftUp}
          onMouseUp={onShiftUp}
          onTouchEnd={onShiftUp}
          onTouchStart={onShiftDown}>
          <Trans>Compare</Trans>
        </BigButton>
        <BigButton
          title={
            selected === null || !hasEverUsedShift
              ? 'Try "Compare" first!'
              : 'Press <SPACEBAR> or <ENTER> to go next'
          }
          disabled={selected === null || !hasEverUsedShift}
          onContextMenu={() => false}
          onClick={onNext}>
          <Trans>Next</Trans>
        </BigButton>
      </ButtonsContainer>
    </Content>
  );

  const adKey = [4, 10, 20, 30, 40, 50].findIndex((m) => m >= level);

  return (
    <Container>
      {level > 3 ? <PageCloseGuard /> : null}
      <Header>
        <Logo />
        <Score score={score} />
      </Header>
      <SupportUkraine href="https://supportukrainenow.org" target="_blank">
        <Trans>🇺🇦 Support Ukraine</Trans>
      </SupportUkraine>
      {content}
      <Footer>
        <ProgressBar
          hasSelected={selected != null}
          level={level}
          levels={levels}
        />
        {adsAvailable && (
          <AdContainer key={adKey}>
            <CarbonNativeAd
              fallback={<CarbonAd onFailedToLoad={onAdFailedToLoad} />}
            />
          </AdContainer>
        )}
      </Footer>
    </Container>
  );
}

function useEventListener(name: string, callback: any) {
  React.useEffect(() => {
    document.addEventListener(name, callback);
    return () => {
      document.removeEventListener(name, callback);
    };
  }, [callback, name]);
}

function useIsMobileLayout() {
  const mediaQuery = window.matchMedia(
    Breakpoints.width.LT701.replace('@media ', '')
  );
  const [isMobileLayout, setIsMobileLayout] = React.useState(
    mediaQuery.matches
  );
  React.useEffect(() => {
    const listener = (mq) => {
      if (mq.matches !== isMobileLayout) {
        setIsMobileLayout(mq.matches);
      }
    };
    mediaQuery.addListener(listener);
    return () => {
      mediaQuery.removeListener(listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileLayout]);
  return isMobileLayout;
}

function useIsTouchScreen() {
  const [isTouch, setIsTouch] = React.useState(false);
  // https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685
  React.useEffect(() => {
    document.addEventListener(
      'touchstart',
      () => {
        setIsTouch(true);
      },
      {once: true} // will remove itself after firing
    );
  }, []);
  return isTouch;
}

function flipCoin(level: number): 'left' | 'right' {
  // Hack hack: we should look at the level and see if we need to
  // hardcode the location of the image. Tutorial levels want to
  // appear in proper order
  if (level === 0) {
    return 'left';
  }
  if (level === 1) {
    return 'right';
  }
  return Math.random() >= 0.5 ? 'left' : 'right';
}

function pointsForComplexity(complexity: number): number {
  switch (complexity) {
    case 1:
      return 10;
    case 2:
      return 100;
    case 3:
      return 150;
    case 4:
      return 200;
    default:
      return 0;
  }
}

const images: Array<any> = [];
function preloadImages() {
  const urls = new Set<string>();
  ALL_LEVELS.forEach((level) => {
    urls.add(level.correct);
    urls.add(level.incorrect);
  });
  urls.forEach((url) => {
    const image = new Image();
    image.src = url;
    images.push(image);
  });
}

function useLocalStorageState(key: string, initial: any) {
  const [state, setState] = React.useState(() => {
    try {
      return JSON.parse(window.localStorage.getItem(key) || '');
    } catch (e) {
      return initial;
    }
  });

  function wrappedSetState(newValue) {
    setState(newValue);
    window.localStorage.setItem(key, JSON.stringify(newValue));
  }

  return [state, wrappedSetState];
}
