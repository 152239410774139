import styled from 'react-emotion';
import * as React from 'react';
import coin from './img/coin.png';
import Logo from './Logo';
import {Rank} from './Rank';
import formatMillis from './formatMillis';
import {Trans} from '@lingui/macro';

type Props = {
  path: string;
  shortcut?: string;
};

const Container = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#62448c',
  color: 'white',
  fontSize: 16,
});

const Score = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 20,
  fontSize: 60,
  color: 'white',
  fontWeight: 'bold',
  flexDirection: 'row',
  '& img': {
    marginRight: 10,
  },
});

export default function TotalScore(props: Props) {
  const shortcut = props.shortcut || '';
  const decoded = atob(shortcut);
  const parts = decoded.split(/\D/);
  if (parts.length < 2) {
    return null;
  }
  const score = Number(parts[0]);
  const millis = Number(parts[1]) * 1000;
  const timeString = formatMillis(millis);
  return (
    <Container>
      <Logo />
      <Score>
        <img key={score} src={coin} width={60} height={60} alt="coin" />
        {score}
      </Score>
      <Rank score={score} />
      <span>
        <Trans>Completed in {timeString}</Trans>
      </span>
    </Container>
  );
}
