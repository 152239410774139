import {t} from '@lingui/macro';

import welcome_00 from './img/welcome/00.png';
import welcome_01 from './img/welcome/01.png';
import welcome_02 from './img/welcome/02.png';
import welcome_03 from './img/welcome/03.png';
import welcome_04 from './img/welcome/04.png';
import welcome_05 from './img/welcome/05.png';
import welcome_06 from './img/welcome/06.png';
import welcome_07 from './img/welcome/07.png';
import welcome_08 from './img/welcome/08.png';
import welcome_09 from './img/welcome/09.png';

import search_00 from './img/search/00.png';
import search_01 from './img/search/01.png';
import search_02 from './img/search/02.png';
import search_03 from './img/search/03.png';
import search_04 from './img/search/04.png';
import search_05 from './img/search/05.png';
import search_06 from './img/search/06.png';
import search_07 from './img/search/07.png';
import search_08 from './img/search/08.png';
import search_09 from './img/search/09.png';

import contact_00 from './img/contact/00.png';
import contact_01 from './img/contact/01.png';
import contact_02 from './img/contact/02.png';
import contact_03 from './img/contact/03.png';
import contact_04 from './img/contact/04.png';
import contact_05 from './img/contact/05.png';
import contact_06 from './img/contact/06.png';
import contact_07 from './img/contact/07.png';
import contact_08 from './img/contact/08.png';
import contact_09 from './img/contact/09.png';

import controls_00 from './img/controls/00.png';
import controls_01 from './img/controls/01.png';
import controls_02 from './img/controls/02.png';
import controls_03 from './img/controls/03.png';
import controls_04 from './img/controls/04.png';
import controls_05 from './img/controls/05.png';
import controls_06 from './img/controls/06.png';
import controls_07 from './img/controls/07.png';
import controls_08 from './img/controls/08.png';
import controls_09 from './img/controls/09.png';

import buttons_00 from './img/buttons/00.png';
import buttons_01 from './img/buttons/01.png';
import buttons_02 from './img/buttons/02.png';
import buttons_03 from './img/buttons/03.png';
import buttons_04 from './img/buttons/04.png';
import buttons_05 from './img/buttons/05.png';
import buttons_06 from './img/buttons/06.png';
import buttons_07 from './img/buttons/07.png';
import buttons_08 from './img/buttons/08.png';
import buttons_09 from './img/buttons/09.png';

import guitar_00 from './img/guitar/00.png';
import guitar_01 from './img/guitar/01.png';
import guitar_02 from './img/guitar/02.png';
import guitar_03 from './img/guitar/03.png';
import guitar_04 from './img/guitar/04.png';
import guitar_05 from './img/guitar/05.png';
import guitar_06 from './img/guitar/06.png';
import guitar_07 from './img/guitar/07.png';
import guitar_08 from './img/guitar/08.png';
import guitar_09 from './img/guitar/09.png';

import tutorial_T01 from './img/tutorial/T01.png';
import tutorial_T02 from './img/tutorial/T02.png';
import tutorial_T11 from './img/tutorial/T11.png';
import tutorial_T12 from './img/tutorial/T12.png';
import tutorial_T21 from './img/tutorial/T21.png';
import tutorial_T22 from './img/tutorial/T22.png';

interface Task {
  id: string;
  description: string;
  incorrect: string;
  correct: string;
  complexity: number;
}

const welcome = [
  [welcome_00, 'welcome_00', ''],
  [welcome_01, 'welcome_01', t`Vertical text alignment`],
  [welcome_02, 'welcome_02', t`Icon size`],
  [welcome_03, 'welcome_03', t`Capitalization`],
  [welcome_04, 'welcome_04', t`Contrast`],
  [welcome_05, 'welcome_05', t`Border radius`],
  [welcome_06, 'welcome_06', t`Spacing between text blocks`],
  [welcome_07, 'welcome_07', t`Button padding`],
  [welcome_08, 'welcome_08', t`Button caption tracking`],
  [welcome_09, 'welcome_09', t`Typo in text block`],
];

const search = [
  [search_00, 'search_00', ''],
  [search_01, 'search_01', t`Placeholder text contrast`],
  [search_02, 'search_02', t`Placeholder text capitalization`],
  [search_03, 'search_03', t`Border radius`],
  [search_04, 'search_04', t`Placeholder text tracking`],
  [search_05, 'search_05', t`Search icon size`],
  [search_06, 'search_06', t`Font weight`],
  [search_07, 'search_07', t`Spacing between icon and text`],
  [search_08, 'search_08', t`Placeholder text baseline`],
  [search_09, 'search_09', t`Search icon resolution`],
];

const contact = [
  [contact_00, 'contact_00', ''],
  [contact_01, 'contact_01', t`Separator width`],
  [contact_02, 'contact_02', t`Subtitle text contrast`],
  [contact_03, 'contact_03', t`Activity indicator color`],
  [contact_04, 'contact_04', t`Subtitle offset`],
  [contact_05, 'contact_05', t`Vertical content alignment`],
  [contact_06, 'contact_06', t`Spacing between title and subtitle`],
  [contact_07, 'contact_07', t`Separator line length`],
  [contact_08, 'contact_08', t`Activity indicator position`],
  [contact_09, 'contact_09', t`Title tracking`],
];

const controls = [
  [controls_00, 'controls_00', ''],
  [controls_01, 'controls_01', t`Consistent icons style`],
  [controls_02, 'controls_02', t`Destructive action button color`],
  [controls_03, 'controls_03', t`Icon contrast`],
  [controls_04, 'controls_04', t`Button background`],
  [controls_05, 'controls_05', t`Vertical button alignment`],
  [controls_06, 'controls_06', t`Icon tint color`],
  [controls_07, 'controls_07', t`Icon alignment`],
  [controls_08, 'controls_08', t`Spacing between buttons`],
  [controls_09, 'controls_09', t`Button shapes`],
];

const guitar = [
  [guitar_00, 'guitar_00', ''],
  [guitar_01, 'guitar_01', t`Price tag contrast`],
  [guitar_02, 'guitar_02', t`Photo aspect ratio`],
  [guitar_03, 'guitar_03', t`Photo aspect ratio`],
  [guitar_04, 'guitar_04', t`Price tag alignment`],
  [guitar_05, 'guitar_05', t`Text wrapping`],
  [guitar_06, 'guitar_06', t`Image resolution`],
  [guitar_07, 'guitar_07', t`Subtitle text separators`],
  [guitar_08, 'guitar_08', t`Card shadow offset`],
  [guitar_09, 'guitar_09', t`Subtitle typography`],
];

const buttons = [
  [buttons_00, 'buttons_00', ''],
  [buttons_01, 'buttons_01', t`Button caption contrast`],
  [buttons_02, 'buttons_02', t`Button background contrast`],
  [buttons_03, 'buttons_03', t`One button with default style`],
  [buttons_04, 'buttons_04', t`Skip button capitalization`],
  [buttons_05, 'buttons_05', t`Button border radius`],
  [buttons_06, 'buttons_06', t`Icon vertical alignment`],
  [buttons_07, 'buttons_07', t`Text letter spacing`],
  [buttons_08, 'buttons_08', t`Skip button font weight`],
  [buttons_09, 'buttons_09', t`Button height`],
];

const categories = [welcome, search, contact, controls, buttons, guitar].map(
  (category) =>
    category.slice(1).map((row) => {
      const [correct] = category[0];
      const [incorrect, id, description] = row;
      return {
        id,
        description,
        correct,
        incorrect,
      };
    })
);

let easy: Task[] = [];
let medium: Task[] = [];
let hard: Task[] = [];

categories.forEach((category) => {
  easy = easy.concat(
    category.slice(0, 3).map((img) => ({...img, complexity: 2}))
  );
  medium = medium.concat(
    category.slice(3, 6).map((img) => ({...img, complexity: 3}))
  );
  hard = hard.concat(category.slice(6).map((img) => ({...img, complexity: 4})));
});

shuffle(easy);
shuffle(medium);
shuffle(hard);

const tutorial = [
  {
    id: 'tutorial_T01',
    description: t`Description will appear here`,
    correct: tutorial_T01,
    incorrect: tutorial_T02,
    complexity: 1,
  },
  {
    id: 'tutorial_T12',
    description: t`Icons, fonts, shapes`,
    correct: tutorial_T12,
    incorrect: tutorial_T11,
    complexity: 1,
  },
  {
    id: 'tutorial_T21',
    description: t`Speech bubble padding`,
    correct: tutorial_T21,
    incorrect: tutorial_T22,
    complexity: 1,
  },
];
const levels = [...tutorial, ...easy, ...medium, ...hard];

function shuffle<T>(a: T[]) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
}

export default levels;
